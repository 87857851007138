import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Typography, Button, CssBaseline } from '@material-ui/core';
import { useHistory, Link } from "react-router-dom";

const NotFound = () => {
    const classes = useStyles();
    let history = useHistory()

    return (
        <React.Fragment >
            <CssBaseline />
            <Container maxWidth="xs" style={{ backgroundColor: 'white', paddingBottom: 20, borderRadius: 10 }}
            >
                <div className={classes.paper} >
                    <div className="clearfix" style={{ textAlign: 'center' }}>
                        <h1>404</h1>
                        <h4 className="pt-3">Oops! You{"'"}re lost.</h4>
                        <p className="text-medium-emphasis float-start">
                            The page you are looking for was not found.
                        </p>
                    </div>
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={e => {
                            e.preventDefault();
                            history.goBack()
                        }}
                    >
                        Go Back
                    </Button>
                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        </React.Fragment>
    );
}
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            {new Date().getFullYear()}
            {' '}
            <Link color="inherit" href="https://ihealthscreen.org/">
                iHealthScreen Inc. All Rights Reserved
            </Link>{' '}

        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));



export default NotFound;
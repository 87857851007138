import React, { useState, useEffect } from "react";
import {
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  Container,
  MenuItem,
  Select,
  InputBase,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import { useStateCallback } from "use-state-callback";
import { colors } from "../utils/constants";

const PrivacyAndPolicy = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Container maxWidth="md" className={classes.container}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <strong
            style={{
              fontSize: "3em",
              color: "white",
              textShadow: "0px 1px 1px rgba(0,0,0,0.8)",
              animation: "bounceIn",
              animationDuration: 0.75,
              animationFillMode: "both",
            }}
          >
            <span className="logo-small-text">I</span>
            <span style={{ fontSize: "1.5em" }}>P</span>
            <span className="logo-small-text">
              REDICT<sup>TM</sup>
            </span>
          </strong>
        </div>
        <div className={classes.formWrapper}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: 10,
              backgroundColor: "white",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
          >
            <strong
              style={{
                fontSize: "1.5em",
                color: colors.appColor,
                textShadow: "0px 1px 1px rgba(0,0,0,0.8)",
                animation: "bounceIn",
                animationDuration: 0.75,
                animationFillMode: "both",
                marginTop: "15px",
              }}
            >
              Terms and Conditions
            </strong>
          </div>

          <div className={classes.termsBg}>
            <p className={classes.termsHeadline}>
              PLEASE READ THESE TERMS AND CONDITIONS OF USE
            </p>
            <p>
              These terms and conditions of use are a legal agreement (these
              “Terms”) between you and iPredict, with offices at Richmond Hill,
              NY 11418 (“iPredict” or “we”, “us” or “our”), establishing terms
              and conditions under which you may access and use the services and
              features available on ipredict.health.The Websites is referred to
              in these Terms as the “Services.”
            </p>
            <p>
              BY ACCESSING OR USING THE SERVICES, YOU AGREE TO BE BOUND BY THESE
              TERMS AND OUR PRIVACY POLICY. IF YOU DO NOT AGREE TO THESE TERMS,
              DO NOT ACCESS OR USE THE SERVICES.
            </p>
            <p>
              By using the Services, you hereby represent and warrant that: (1)
              you are an individual of at least eighteen (18) years of age; (2)
              you have the legal power and authority to agree to, and be bound
              by, these Terms; (3) if you have provided information to us in
              connection with your use of the Services, such information is true
              and accurate.
            </p>
            <p>
              We may revise and update these Terms at any time and without
              notice. Your continued use of the Services will constitute your
              acceptance of these changes and the current version of these
              Terms.
            </p>
            <p className={classes.termsHeadline}>
              THE SERVICES DO NOT PROVIDE MEDICAL ADVICE
            </p>
            <p>
              The information and content in the Services, including but not
              limited to text, graphics, images, videos, and other material
              contained in the Services is for informational purposes only and
              is not intended as a substitute for professional medical advice,
              help, diagnosis or treatment. Always seek the advice of your
              physician or other qualified health care provider with any
              questions you have regarding your medical care, and never
              disregard professional medical advice or delay seeking it because
              of something you have read on or via the Services. Nothing
              contained in the Services is intended to constitute a medical
              diagnosis or treatment. Nothing in the Services is intended as a
              recommendation or endorsement of any specific test, product,
              procedure, opinion, or other information that may be mentioned in
              the Services. Reliance on any information appearing in the
              Services, including but not limited to information provided by
              iPredict personnel or by other users of the Services, is solely at
              your own risk.
            </p>
            <p>
              iPredict does not warrant the accuracy, completeness, timeliness
              or usefulness of the opinions, advice, content, services or other
              information provided through the Services or on the Internet
              generally. The Services may contain health or medical-related
              materials or information that you may find sexually explicit or
              otherwise offensive.
            </p>
            <p>
              iPredict and its affiliates, licensors, and suppliers have no
              control over and accept no responsibility for your compliance with
              the laws applicable to your country or state of residence.
            </p>
            <p className={classes.termsHeadline}>
              INTELLECTUAL PROPERTY RIGHTS AND RESTRICTIONS
            </p>
            <p>
              iPredict hereby grants you a personal, non-exclusive,
              non-transferable, limited, revocable right to use the Services for
              personal, non-commercial purposes only and in accordance with
              these Terms. You agree not to use the Services for any other
              purpose, including but not limited to any commercial purpose,
              without the prior written consent of iPredict. For example, you
              may not (and may not direct or authorize any other party to), (1)
              co-brand any Website, App, or any portion thereof, (2) frame any
              Website or any portion thereof (whereby the Website will appear on
              the same screen with a portion of another website), or (3) link
              any Website to any other website. "Co-brand" means to display a
              name, logo, trademark or other means of attribution or
              identification of any party in such a manner as is reasonably
              likely to give a user of the Services the impression that such
              party has the right to display, publish, or distribute the
              Services or any content accessible within the Services. You agree
              to cooperate with iPredict in causing any unauthorized
              co-branding, framing or linking immediately to cease.
            </p>
            <p>
              The material and content accessible within the Services and any
              other website or mobile application owned, operated, licensed, or
              controlled by iPredict (collectively, the "Content") is the
              intellectual property and proprietary information of iPredict or
              the party that provided the Content to iPredict. All title to,
              ownership of, and rights in and to such Consent is, and at all
              times shall remain, the sole and exclusive property of iPredict
              and its content providers (as the case may be). Accordingly, you
              may not copy, distribute, republish, upload, post, transmit, or
              create derivative works of the Content in any way without the
              prior written consent of iPredict, except that you may print out a
              copy of the Content solely for your personal use. In doing so, you
              may not remove or alter, or cause or direct to be removed or
              altered, any copyright, trademark, service mark, trade name, or
              any other proprietary notice or legend appearing on the Services
              or on any of the Content.
            </p>
            <p>
              You may not use any iPredict names or trademarks in any
              advertising or publicity, or otherwise to indicate or imply
              sponsorship of or affiliation with any product or service, without
              the prior written consent of iPredict.
            </p>
            <p>
              The Services may contain hyperlinks, directly or by means of
              search results, to other websites that are not maintained by, or
              affiliated with, iPredict. Hyperlinks to such third-party websites
              are provided as a service to users and are not sponsored by or
              affiliated with the Services or with iPredict. iPredict has not
              reviewed any or all of such websites and is not responsible for
              the content of those websites. Hyperlinks are to be accessed at
              your own risk, and iPredict makes no representations or warranties
              about the content, completeness, or accuracy of these hyperlinks
              or the sites hyperlinked to the Services. Further, the inclusion
              of any hyperlink to a third-party website does not necessarily
              imply endorsement by iPredict of that website or the material or
              content contained therein.
            </p>
            <p className={classes.termsHeadline}>
              ADDITIONAL LICENSE TERMS THAT APPLY TO THE APPS
            </p>
            <p>
              The service are contractual, not sold, to you. Your service is
              subject to your prior acceptance of these Terms.
            </p>
            <p>
              The license granted to you herein by iPredict for the Apps is
              limited to a non-transferable license to use the Apps on
              authorized devices. For example, iOS-based Apps may be used only
              on devices running iOS (including but not limited to iPad and
              iPhone) that you own or control. This license does not allow you
              to use the Apps on any device that you do not own or control, and
              you may not distribute or make any App available over a network
              where it could be used by multiple devices at the same time. You
              may not rent, lease, lend, sell, transfer, redistribute, or
              sublicense the Apps and, if you sell your device to a third party,
              you must remove the Apps from the device before doing so. You may
              not copy (except as expressly permitted by this license),
              decompile, reverse-engineer, disassemble, attempt to derive the
              source code of, modify, or create derivative works of the Apps,
              any updates, or any part thereof (except as and only to the extent
              that any foregoing restriction is prohibited by applicable law).
              Any attempt to do so is a violation of the rights of iPredict and
              its licensors. If you breach this restriction, you may be subject
              to prosecution and damages.
            </p>
            <p>
              The terms of this license will govern any upgrades provided by
              iPredict that replace and/or supplement the original Apps, unless
              such upgrade is accompanied by a separate license in which case
              the terms of that license will govern.
            </p>
            <p>
              The license is effective until terminated by you or iPredict. Your
              rights under this license will terminate automatically without
              notice from iPredict if you fail to comply with any term(s) of
              this license. Upon termination of the license, you shall cease all
              use of the Apps and destroy all copies, full or partial, of the
              Apps.
            </p>
            <p>
              Location data provided by the Apps is for basic navigational
              purposes only and is not intended to be relied upon in situations
              where precise location information is needed or where erroneous,
              inaccurate, time-delayed, or incomplete location data may lead to
              death, personal injury, property, or environmental damage.
            </p>
            <p>
              You may not use or otherwise export or re-export the Apps except
              as authorized by United States law. In particular, but without
              limitation, the Apps may not be exported or re-exported (a) into
              any U.S.-embargoed countries or (b) to anyone on the U.S. Treasury
              Department's Specially Designated Nationals List or the U.S.
              Department of Commerce Denied Persons List or Entity List. By
              using the Apps, you represent and warrant that you are not located
              in any such country or on any such list. You also agree that you
              will not use the Apps for any purposes prohibited by United States
              law, including, without limitation, the development, design,
              manufacture, or production of nuclear, missile, or chemical or
              biological weapons.
            </p>
            <p className={classes.termsHeadline}>CONSENT TO USE OF DATA</p>
            <p>
              You agree that, consistent with our Privacy Policy, iPredict may
              collect and use technical data and related information—including
              but not limited to technical information about your device, system
              and application software, and peripherals—that is gathered
              periodically to facilitate the provision of software updates,
              product support, and other services to you (if any) related to the
              Services. iPredict may use this information, as long as it is in a
              form that does not personally identify you, to improve its
              products or to provide services or technologies to you. For more
              information.
            </p>
            <p className={classes.termsHeadline}>COMMUNICATION POLICY</p>
            <p>
              In the event the following functionality exists on the Services,
              iPredict reserves the right but is not obligated to do any or all
              of the following:
            </p>
            <p>
              (a) Record the dialogue in any public chat rooms;
              <br />
              (b) Investigate any allegation that a communication does not
              conform to the terms of this section and determine in its sole
              discretion to remove or request the removal of the communication;
              <br />
              (c) Remove any communication that in iPredict’s sole discretion is
              abusive, illegal, disruptive, or otherwise fails to comply with
              these Terms;
              <br />
              (d) Terminate your access to the Services or any area thereof upon
              any breach of these Terms; (e) Monitor, edit, or disclose any
              communication in the public areas; and
              <br />
              (f) Edit or delete any communication posted on the Services,
              regardless of whether such communication violates these standards.
            </p>
            <p>iPredict UPDATES SHORT CODE TERMS OF SERVICE</p>
            <p>
              When you opt-in to the service, we will send you a message to
              during login securely.
            </p>
            <p className={classes.termsHeadline}>NO WARRANTY</p>
            <p>
              THE SERVICES ARE PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTIES
              OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY
              IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, NON-INFRINGEMENT, OR TITLE. iPredict HEREBY DISCLAIMS ANY
              AND ALL SUCH WARRANTIES. iPredict DOES NOT WARRANT THAT THE
              CONTENT CONTAINED IN THE SERVICES WILL BE UNINTERRUPTED OR
              ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICES
              OR THE SERVERS THAT MAKE THE SERVICES AVAILABLE ARE FREE OF
              VIRUSES OR OTHER HARMFUL COMPONENTS. iPredict DOES NOT WARRANT OR
              MAKE ANY REPRESENTATION REGARDING USE, OR THE RESULT OF USE, OF
              THE CONTENT ON THE SERVICES IN TERMS OF ACCURACY, RELIABILITY, OR
              OTHERWISE. THE SERVICES MAY INCLUDE TECHNICAL INACCURACIES OR
              TYPOGRAPHICAL ERRORS, AND iPredict MAY MAKE CHANGES OR
              IMPROVEMENTS AT ANY TIME. iPredict MAKES NO WARRANTIES THAT YOUR
              USE OF THE SERVICES WILL NOT INFRINGE THE RIGHTS OF OTHERS AND
              ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ERRORS OR OMISSIONS IN
              THE CONTENT PROVIDED HEREIN.
            </p>
            <p>
              BY ACCESSING THE SERVICES, YOU ASSUME ALL RESPONSIBILITY AND RISK
              WITH RESPECT TO YOUR USE OF THE SERVICES AND ANY PRODUCTS OR
              SERVICES DESCRIBED HEREIN.
            </p>
            <p className={classes.termsHeadline}>LIMITATION OF LIABILITY</p>
            <p>
              Under no circumstances shall iPredict, or any of its directors,
              officers, affiliates, employees, agents, representatives, medical
              staff, successors, or assigns be liable to you or any other party
              for any punitive, special, incidental, indirect, or consequential
              damages whatsoever, however arising (including, without
              limitation, our negligence), including, without limitation,
              damages resulting from loss of use, data, sales, goodwill or
              profits, whether or not we have been advised of such possibility,
              or under any legal or equitable theory, in tort, in contract, at
              equity, or otherwise, arising out of or in connection with your
              use of the Services.
            </p>
            <p>
              Your sole and exclusive remedy for dissatisfaction with the
              Services is to stop using the Services. All information is
              transmitted over a medium that is beyond the control and
              jurisdiction of iPredict. Accordingly, iPredict assumes no
              responsibility for, or relating to, delay, failure, interruption,
              or corruption of any data or other information transmitted in
              connection with use of the Services or other sites or services
              accessed via the Services.
            </p>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, iPredict DISCLAIMS ANY AND
              ALL LIABILITIES OR OBLIGATIONS WHATSOEVER RELATED TO THE USE OF
              THE SERVICES BY ANYONE OTHER THAN YOU.
            </p>
            <p className={classes.termsHeadline}>GENERAL</p>
            <p>
              These Terms shall be governed by and construed in accordance with
              the laws of the State of New York, without regard to conflicts of
              laws principles.
            </p>
            <p>
              These Terms can be modified by iPredict at any time, and such
              modification shall be effective immediately upon posting. You
              agree to review these Terms periodically to be aware of such
              modification and your access or use of the Services shall be
              deemed your acceptance of the modified agreement.
            </p>
            <p className={classes.termsHeadline}>PRIVACY</p>
            <p>
              <b>Introduction</b>: This policy applies to all personal
              information collected by iPredict.health, both online and offline.
            </p>

            <p>
              <b>Information Collection</b>: iPredict.health collects personal
              information from users when they sign up for an account, make a
              purchase, or participate in surveys. This information may include
              name, email address, and medical information.
            </p>

            <p>
              <b>Use of Information</b>: iPredict.health may use personal
              information to provide users with personalized content and
              services, to send email or other communications, and for internal
              research and analysis.
            </p>

            <p>
              <b>Data Sharing</b>: iPredict.health does not share personal
              information with third parties, except as required by law or to
              protect the rights of iPredict.health.
            </p>

            <p>
              <b>Security Measures</b>: iPredict.health uses a variety of
              security measures to protect personal information, including
              encryption, firewalls, and secure servers.
            </p>

            <p>
              <b>User Rights</b>: Users have the right to access, correct, or
              delete their personal information, as well as the right to opt-out
              of any communication from iPredict.health.
            </p>

            <p>
              <b>Changes to Policy</b>: This policy may be updated from time to
              time, and any changes will be posted on the iPredict.health
              website.
            </p>
            <p>
              Any questions or comments should be directed to
              info@ihealthscreen.org.
            </p>
          </div>

          <Grid container justifyContent="center">
            <Grid item>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => {
                  history.push("/register");
                }}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>

      {/* <Copyright /> */}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    backgroundColor: "transparent",
    width: "100%",
    padding: 0,
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  formWrapper: {},
  form: {
    backgroundColor: "white",
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: "100%", // Fix IE 11 issue.Register
    padding: "2%",
  },
  input: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  inputLabel: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingRight: "2%",
    paddingLeft: "1%",
    justifyContent: "flex-start",
    fontWeight: "bold",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textField: {
    height: 10,
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  inputTextContainer: {
    textAlign: "right",
    marginRight: "5%",
  },
  copyRight: {
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: "5em",
    [theme.breakpoints.up("sm")]: {
      top: "8em",
    },
    [theme.breakpoints.up("md")]: {
      top: "12em",
    },
    [theme.breakpoints.down("sm")]: {
      top: "1em",
    },
  },
  termsBg: {
    backgroundColor: "white",
    padding: "20px 30px",
    fontSize: "17px",
  },
  termsHeadline: {
    fontWeight: "bold",
  },
}));
const styles = (theme) => ({
  "@global": {
    body: {
      height: "100%",
    },
    html: {
      height: "100%",
    },
    "#componentWithId": {
      height: "100%",
    },
  },
});
export default withStyles(styles)(PrivacyAndPolicy);

import React from 'react';
// import { useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter}from "react-router-dom"
// routing
import Routes from './routes';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import { Provider } from 'react-redux';
import { CssBaseline } from '@material-ui/core';
import { theme } from './utils/theme';
const App = () => {
  // const customization = useSelector((state) => state.customization);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <SnackbarProvider maxSnack={2} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
            TransitionComponent={Slide}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Routes />
            </ThemeProvider>
          </SnackbarProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;

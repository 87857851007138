require("dotenv").config();

export const urls = {
  baseUrl: process.env.REACT_APP_API_URL,
};
export const colors = {
  appColor: "#360064",
  appBar: "rgba(61, 142, 189, 0.5)",
  sectionBar: "rgba(61, 142, 189)",
  appBackground: "#ffffff",
  borderColor: "#E3E3E3",
  grey: "grey",
  dark: "black",
  disabled: "#CFCFCB",
  success: "#6CBF6D",
  warning: "#FF7000",
  info: "#007ACC",
  fav1: "#581845",
  fav2: "#F3F2EC",
  danger: "#C70039",
  inputContainer: "#F8F9FA",
  fav0: "#78B7BB",
  getRandomColor: () => {
    var letters = "012345".split("");
    var color = "#";
    color += letters[Math.round(Math.random() * 5)];
    letters = "0123456789ABCDEF".split("");
    for (var i = 0; i < 5; i++) {
      color += letters[Math.round(Math.random() * 15)];
    }
    return color;
  },
};
export const usStates = [
  {
    name: "Ajman",
    abbreviation: "AJ",
  },
  {
    name: "Abu Dhabi",
    abbreviation: "AZ",
  },
  {
    name: "Fujairah",
    abbreviation: "FU",
  },
  {
    name: "Sharjah",
    abbreviation: "SH",
  },
  {
    name: "Dubai",
    abbreviation: "DU",
  },
  {
    name: "Ras Al Khaimah	",
    abbreviation: "RK",
  },
  {
    name: "Umm Al Quwain",
    abbreviation: "UQ",
  },
];
